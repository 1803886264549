@font-face {
  font-family: 'Rising Sun';
  src: url('../../../assets/fonts/RisingSun-Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.section9_block {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(232, 234, 238, 0.1);
  backdrop-filter: blur(100px);
  border-radius: 32px;
  max-height: 638px;
  overflow: hidden;
}

.image img {
  border-radius: 32px;
  position: relative;
}
.glow {
  position: absolute;
  width: 24.7vw;
  height: 385px;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(19, 84, 122, 0.9) 0%,
    rgba(128, 208, 199, 0.9) 100%
  );
  filter: blur(100px);
}

.text {
  padding: 40px 40px 40px 0;
}

.text h2 {
  font-size: 40px;
  line-height: 120%;
  text-align: right;
  color: #e8eaee;
  margin-bottom: 40px;
}

.text h3 {
  font-family: 'Rising Sun';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #e8eaee;
  margin-bottom: 20px;
}

.text p {
  font-family: 'Rising Sun';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: right;
  color: #bdc0c3;
  margin-bottom: 40px;
}

.more_info {
  font-family: 'Rising Sun';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  text-align: right;
  color: #7c8083;
}

.text .button {
  margin: 20px 0 0 auto;
  padding: 14px 64px;
  width: fit-content;
  background: linear-gradient(63.43deg, #13547a 0%, #80d0c7 83.33%);
  box-shadow: -1px 4px 8px rgba(6, 13, 17, 0.1);
  border-radius: 12px;
  font-family: 'Rising Sun';
  font-weight: 500;
  font-size: 20px;
  color: #10161a;
  transition: 0.3s;

  position: relative;
  background: transparent;
  z-index: 1;
  cursor: pointer;
}

.text .button::before {
  content: "";
  position: absolute;
  border-radius: inherit;
  top:0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(63.43deg, #13547A 0%, #80D0C7 83.33%);
  z-index: -1;
  transition: inherit;
}

.text .button::after {
  content: "";
  position: absolute;
  border-radius: inherit;
  top:0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(63.43deg, #1178A4 0%, #8CDBD8 83.33%);
  z-index: -1;
  opacity: 0;
  transition: inherit;
}

.text .button:hover::after {
  transition: inherit;
  opacity: 100;
}

.text .button:active {
  background-color: #185a7e; /* Pressed state */
  border: 2px solid #185a7e;
}

.text .button:focus {
  background-color: #73c1be; /* Focus state */
  border: 2px solid #185a7e;
}

.button {
  display: flex;
  justify-content: flex-end;
}

/* Add these media queries at the end of your CSS file */


/* For screens with a maximum width of 1024px */
@media (max-width: 1360px) {
  .section9_block {
    flex-direction: column;
    gap: 40px;
    padding: 40px;
    max-height: 100%;
  }

  .image {
    display: none;
  }

  .text {
    order: 1;
    padding: 0px;
  }

  .more_info {
    text-align: left;
  }

  .text p {
    text-align: left;
  }

  .text h2 {
    text-align: left;
  }

  .text h3 {
    text-align: left;
  }
  .button {
    display: block;
  }
}

/* For screens with a maximum width of 768px */
@media (max-width: 768px) {
  .text h2 {
    font-size: 36px;
  }

  .text h3 {
    font-size: 20px;
  }

  .section9_block {
    padding: 20px;
  }

  .text p {
    font-size: 18px;
  }

  .more_info {
    font-size: 16px;
  }

  .text button {
    width: 100%;
  }
}

/* For screens with a maximum width of 480px */
@media (max-width: 480px) {
  .section9_block {
    margin-top: 50px;
  }

  .text h2 {
    font-size: 28px;
  }

  .text h3 {
    font-size: 18px;
  }

  .text p {
    font-size: 16px;
  }
}
