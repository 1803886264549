.modal {
  color: #bdc0c3;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 20px;
  z-index: 1001;
  background: #10161a;
}

.modalTransparentBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1000;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.modalHeader h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
}

.modalBody {
  padding: 10px;
}

.modalClose {
  color: #73c1be;
  padding: 10px 10px;
  background-color: #10161a;
  float: right;
  cursor: pointer;
}

.modalBody h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.modalBody input[type='text'],
.modalBody input[type='password'] {
  width: 100%;
  padding: 14px;
  margin-bottom: 15px;
  color: #E8EAEE;
  border: 1px solid #E8EAEE;
  background-color: #10161a;
  border-radius: 12px;
  outline: none;
}

.submit {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modalBody button[type='submit'] {
  padding: 14px 24px;
  gap: 8px;
  background: #73c1be;
  border-radius: 12px;
  cursor: pointer;
}

.errorMessage {
  color: #ff6161;
  font-size: 14px;
  margin-top: 10px;
}

@media (max-width: 700px) {
  .modal {
    width: 70%;
  }
}

@media (max-width: 430px) {
  .modal {
    width: 85%;
  }
}