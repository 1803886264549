@font-face {
    font-family: 'Rising Sun';
    src: url('../../assets/fonts/RisingSun-Regular.otf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
  
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 120px;
    z-index: 1000;
    transition: background-color 0.3s ease;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100vw;
    margin-top: 30px;
}

.telegram a {
    display: flex;
    align-items: center;
    gap: 15px;
}

.black_background {
    position: fixed;
    width: 100vw;
    background: rgba(16, 22, 26, 0.8);
    backdrop-filter: blur(50px);
    margin-top: 0px;
}

.active_link {
    color: #73C1BE;
}
.ne_active_link {
    color: #E8EAEE;
    transition: 0.25s;
}
.ne_active_link:hover {
    color: #73C1BE;
}


@media (max-width: 1440px) {
	.header {
	  padding: 20px 120px; /* Отступы для контейнера на экранах меньше 1440px */
	}
}

@media (max-width: 1024px) {
	.header {
		padding: 20px 40px; /* Отступы для контейнера на экранах меньше 1440px */
        top: -10px
	}
	br {
		display: none;
	}
}

@media (max-width: 900px) {
    .header {
        top: -10px;
    }
}

@media (max-width: 768px) {
	.header {
		padding: 10px 20px;
	}
}

.header_link ul {
    display: flex;
    gap: 40px;
}

.header_link ul a {
    font-size: 20px;
    text-decoration: none;
    font-size: 20px;
    line-height: 28px;
    font-family: 'Rising Sun';
}

.header_nav {
    display: flex;
    align-items: center;
    gap: 40px;
}


.language {
    display: flex;
    align-items: center;
    gap: 16px;
}

.language p {
    font-size: 20px;
    line-height: 28px;
    color: #E8EAEE;
}

.language_list {
    position: absolute;
    margin-top: 16px;
    cursor: pointer;
}

.language_list img {
    width: 30px;
}

.language_flag {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
}

.language_name {
    font-size: 20px;
    line-height: 28px;
    color: #E8EAEE;
    font-family: 'Rising Sun';
}
.language_flag img {
    width: 30px;
}

.telegram {
    display: none;
}

.login_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    gap: 8px;
    background: #73C1BE;
    border-radius: 12px;
    transition: 0.25s;
    cursor: pointer;
}

.login_button:hover {
    background: #8AD9D2;
}

.login_button:active {
    background: #5A989B;
}

.login_button p {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #10161A;
    font-family: 'Rising Sun';
}

.burger_menu {
    display: none;
  }

  @media (max-width: 1024px) {
    .header {
        margin-top: 10px;
    }
  }

  @media (max-width: 900px) {
    .header_nav {
        display: block;
        position: absolute;
        top: 0;
        left: -100%;
        background-image: url(../../assets/images/header-bg.png);
        background-position: center ;
        background-size: cover;
        width: 100vw;
        height: 100vh;
        z-index: 99;
        overflow: hidden;
        transition: 0.35s;
        padding: 120px 40px 40px 40px;
        overflow: hidden;
    }

      .burger_menu {
          padding: 15px 10px;
          display: flex;
          flex-direction: column;
          gap: 6px;
          cursor: pointer;
          z-index: 100;
      }

    .burger_item {
        width: 28px;
        height: 2px;
        background: #73c1be;
        border-radius: 4px;
        transition: 0.35s;
    }

      .burger_active_menu .burger_item:nth-child(1) {
          transform: translateY(8px) rotate(45deg);
      }
      .burger_active_menu .burger_item:nth-child(2) {
          opacity: 0;
      }
      .burger_active_menu .burger_item:nth-child(3) {
          transform: translateY(-8px) rotate(-45deg);

      }

    .telegram {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 175px;
    }

    .telegram a {
        font-family: 'Igra Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
    }

    .header_link ul {
        flex-direction: column;
        gap: 20px;
        border-radius: 8px;
        margin-bottom: 20px;
      }

    .lan {
        margin-bottom: 60px;
    }

    .active_menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
  }
  